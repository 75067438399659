.video {
    display: block;
    position: relative;
    height: 700px;
    width: 100%;
    overflow: hidden;

    .cd-intro-content {
        display: block;
        height: 100%;
        position: relative;
        vertical-align: middle;
        text-align: center;

        * {
            opacity: 1;
        }
    }

    .svg-mask {
        position: absolute;
        left: 50%;
        top: 50%;
        bottom: auto;
        right: auto;
        transform: translateX(-50%) translateY(-50%);
        opacity: 0.4;
    }

    .svg-wrapper {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    .mobile {
    }

    .tablet {
        display: none;
    }

    .desktop {
        display: none;
    }

    .text1 {
        bottom: calc(50% + 100px);
        position: absolute;
        z-index: 3;
        margin: 0 auto;
        width: 100%;
        font-weight: bold;
        font-size: 25px;
        display: none;
    }

    .text2 {
        bottom: calc(50% - 160px);
        position: absolute;
        z-index: 3;
        margin: 0 auto;
        width: 100%;
        font-weight: bold;
        font-size: 20px;
        display: none;
    }
}

@media (min-width: 768px) {
    .video {
        .mobile {
            display: none;
        }
    
        .tablet {
            display: block;
        }
    
        .desktop {
            display: none;
        }

        .text1 {
            display: block;
        }

        .text2 {
            display: block;
        }
    }
}

@media (min-width: 1024px) {
    .video {
        .mobile {
            display: none;
        }
    
        .tablet {
            display: none;
        }
    
        .desktop {
            display: block;
        }
    }
}
