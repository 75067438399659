@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:300");

$primary-color: #1e262c;
$secondary-color: #ecbc2e;
$primary-color-light: #33414a;
$secondary-color-light: #fbf1d5;

.main-navigation {
	height: 65px;
	padding-top: 20px;

	.main-navigation-toggler {
		position: relative;
		width: 50px;
		font-family: "Josefin Sans", sans-serif;
		color: $primary-color;
		cursor: pointer;
		margin: 0 auto;
		font-size: 32px;

		&::before,
		&::after {
			position: absolute;
			transition: 0.4s ease;
			opacity: 0;
			pointer-events: none;
		}

		&:before {
			content: "M \00a0 \00a0 \00a0 \00a0 \00a0U";
			top: -60px;
			left: -40%;
			width: 215px;
		}

		&:after {
			top: 50px;
			content: "N";
			left: 80%;
		}

		&:hover::before,
		&:hover::after {
			top: -9px;
			opacity: 1;
			transition: 0.2s ease, opacity 0.17s 0.03s ease-in;
		}

		.bar {
			width: 50%;
			height: 2px;
			background: $primary-color;
			margin: 0 auto 8px;
			transition: transform 0.4s ease;
		}
	}

	.main-navigation-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 12;
    background-color: white;

		.cross-wrap {
			width: 26px;
			height: 26px;
			cursor: pointer;
			position: relative;

			span {
				position: absolute;
				display: block;
				width: 100%;
				height: 2px;
				border-radius: 6px;
				background: $primary-color;

				&.top {
					top: 12px;
					left: 0;
					transform: rotate(45deg);
				}

				&.bottom {
					bottom: 12px;
					left: 0;
					transform: rotate(-45deg);
				}
			}
		}
	}

	.main-navigation-menu {
		background: $primary-color;
		position: fixed;
		top: 0;
		left: -310px;
		z-index: 1000;
		width: 300px;
		height: 100%;
		transition-duration: 500ms;
		padding: 0;
		display: block;
		overflow-x: hidden;
		overflow-y: scroll;
    padding-top: 60px;

    ul {

      li {
				display: block;
				position: relative;
				clear: both;
				z-index: 10;

				a {
					display: block;
					font-size: 1.2rem;
					padding: .9em 2em .9em 1em;
					border-bottom: 1px solid $primary-color-light;
					color: #fff;
					text-decoration: none;
				}
			}
		}

		&.menu-on {
			left: 0;
		}
	}
}

@media only screen and (min-width: 1024px) {
  .main-navigation {
		padding-top: 0;

    .main-navigation-toggler {
      display: none;
    }

    .main-navigation-menu {
      position: relative;
			background-color: transparent;
			border-bottom: $primary-color-light 1px solid;
  		width: 100%;
      left: 0;
      padding: 0;

      .main-navigation-close-icon {
        display: none;
      }

      .main-navigation-links {
        background-color: white;
      }
   
      ul {
				margin-bottom: 0;
  
        li {
          display: inline-block;

					a {
            border-bottom: none 0;
						color: $primary-color;
						
						&:hover {
							color: $secondary-color;
						}
          }
        }
      }
    }
  }
}
