.topsocialarea {
	text-align: right;
	svg {
    color: #ffffff;
  }
	a {
		font-size: 15px;
    padding: .9em 2em .9em 1em;
	}

  &.is-homepage {
    svg {
      color: #000000;
    }
  }
}
