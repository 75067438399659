.header {
	position: relative;
	width: 100%;

	.header_top {
		height: 65px;
		background-color: #000;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		a {
			text-decoration: none;
			color: #fff;
		}

		.header_top_left {
			width: 75%;

			a {
				font-size: 25px;
				padding: .9em 2em .9em 1em;
			}
		}
		.header_top_right {
			width: 25%;
		}
	}

	&.is-homepage .header_top {
		background-color: #fff;
		a {
			color: #000;
		}
	}

	.header_nav {
		background-color: #fff;
		height: 65px;
	}
}
