@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url(css/bootstrap.min.css);
@font-face {
  font-family: "helveticaneuebold";
  src: url("fonts/helvetica_neu_bold-webfont.woff2") format("woff2"),
    url("fonts/helvetica_neu_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "helveticaneuemedium";
  src: url("fonts/helveticaneue_medium-webfont.woff2") format("woff2"),
    url("fonts/helveticaneue_medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-size: 12px;
  background-color: #ffffff;
}

p {
  font-size: 1em; /* 12px */
  line-height: 2em; /* 24px */
  margin: 0 0 2em 0;
  font-family: Arial, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
  font-weight: 500;
  line-height: 2em; /* 24px */
}

.text ul li {
  margin: 0 20px;
}

a {
  transition-duration: 500ms;
  text-decoration: none;
  outline: none;
  font-size: 14px;
  color: #4f4d4d;

  &:hover,
  &:focus {
    transition-duration: 500ms;
    text-decoration: none;
    outline: none;
    font-size: 14px;
  }
}

img {
  height: auto;
  max-width: 100%;
  width: 100%;
}
